<template>
  <BaseLoader v-if="users === null" />
  <div v-else>
    <h1>Warehouse Activities</h1>
    <div class="row" style="max-width: 400px;">
      <div class="col">
        <label>From</label>
        <select class="custom-select" v-model="startHour">
          <option v-for="i in 24" :key="i" :value="i - 1">{{ i - 1 }}</option>
        </select>
      </div>
      <div class="col">
        <label>To</label>
        <select class="custom-select" v-model="endHour">
          <option v-for="i in 23" :key="i" :value="i - 1">{{ i }}</option>
        </select>
      </div>
    </div>

    <div v-if="users === false" class="alert alert-warning mt-3">No activities found for the given date.</div>
    <div v-else v-for="(hours, uid, i) in users" :key="uid">
      <h2 class="h3 mt-4">Tablet {{ i + 1 }}</h2>
      <div class="warehouse-activities__timeline">
        <div v-for="(activities, hour) in hours" :key="uid + hour" class="warehouse-activities__hour">
          <span class="warehouse-activities__hour__time">{{ hour }}h</span>
          <div
            v-for="(activity, i) in activities"
            :key="uid + hour + i"
            :class="`warehouse-activities__activity warehouse-activities__activity--${activity.action}`"
            :style="
              `left: ${activity.hourlyProgress * 100}%; background-color: ${
                activity.orderId ? stringToColour(activity.orderId) : '#000'
              }`
            "
          >
            <ul class="warehouse-activities__activity__details">
              <li class="warehouse-activities__activity__details__action">{{ activity.action }}</li>
              <li>{{ activity.timestamp.toDate().toLocaleTimeString() }}</li>
              <li>{{ activity.orderId }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'

export default {
  data() {
    return {
      activitiesSubscription: null,
      userData: null,
      startHour: 9,
      endHour: 17
    }
  },
  created() {
    this.activitiesSubscription = db
      .doc(`private/logs/warehouseActivities/${this.day}`)
      .onSnapshot(doc => (this.userData = doc.data()))
  },
  computed: {
    day() {
      return this.$route.params.day
    },
    users() {
      if (this.userData === null) return false
      const users = {}
      Object.keys(this.userData).forEach(userId => {
        const timeline = {}
        for (let hour = this.startHour; hour <= this.endHour; hour++) {
          timeline[hour] = []
        }
        this.userData[userId].forEach(activity => {
          const timestamp = activity.timestamp.toDate()
          const hours = timestamp.getHours()
          const totalSeconds = timestamp.getMinutes() * 60 + timestamp.getSeconds()
          activity.hourlyProgress = totalSeconds / 3600
          if (timeline[hours]) timeline[hours].push(activity)
        })
        users[userId] = timeline
      })
      return users
    }
  },
  methods: {
    stringToColour(str) {
      var hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      var colour = '#'
      for (let i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    }
  }
}
</script>

<style lang="scss" scoped>
.warehouse-activities {
  &__timeline {
    display: flex;
    width: 100%;
  }
  &__hour {
    flex-grow: 1;
    flex-basis: 1;
    position: relative;
    height: 150px;

    border: 1px #ccc solid;
    border-radius: 10px;

    &__time {
      padding: 0.5rem 0 0 0.5rem;
      font-size: 0.8rem;
    }
  }
  &__activity {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    position: absolute;
    border: 1px rgba(0, 0, 0, 0.25) solid;

    ul {
      list-style: none;
      margin: 0;
      padding: 1rem;
    }

    &--pick {
      top: calc(25% - 4px);
    }
    &--pack {
      top: calc(50% - 4px);
    }
    &--finishStep {
      top: calc(75% - 4px);
    }

    &--orderClosed {
      top: 0;
      bottom: 0;
      height: 100%;
      width: 2px;
    }

    &__details {
      display: none;
      background: #fff;
      border: 1px #ccc solid;
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 100;

      &__action {
        text-transform: capitalize;
      }
    }

    &:hover {
      .warehouse-activities__activity__details {
        display: block;
      }
    }
  }
}
</style>
